var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-text-field',{attrs:{"label":_vm.placeholder,"single-line":"","outlined":"","color":"primary","background-color":"white","rules":[
    _vm.max16 ? _vm.rules.max16 : true,
    _vm.min8 ? _vm.rules.min8 : true,
    _vm.alfaNumeric ? _vm.rules.alfaNumeric : true,
    _vm.number ? _vm.rules.number : true,
    _vm.required ? _vm.rules.required : true,
  ],"maxlength":_vm.length,"type":_vm.auxPass ? 'password' : 'text',"append-icon":_vm.password ? (_vm.auxPass ? 'mdi-eye-outline' : 'mdi-eye-off-outline') : '',"elevation":"0"},on:{"input":(value) => _vm.Change(value),"click:append":function($event){_vm.auxPass = !_vm.auxPass}},model:{value:(_vm.localvalue),callback:function ($$v) {_vm.localvalue=$$v},expression:"localvalue"}},[_vm._v(" >")])
}
var staticRenderFns = []

export { render, staticRenderFns }